<template>
  <div class="d-flex align-items-center">
    <span>{{ formattedValue }}</span>
    <div
      v-if="rating.state"
      class="d-flex align-center pl-25"
    >
      <feather-icon
        v-if="rating.state === 'up'"
        icon="ArrowUpIcon"
        class="text-success"
      />
      <feather-icon
        v-if="rating.state === 'down'"
        icon="ArrowDownIcon"
        class="text-danger"
      />
    </div>
  </div>
</template>

<script>
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue';

export default {
  name: 'EmployeeRating',
  components: { FeatherIcon },
  props: {
    rating: {
      type: Object,
      default: () => ({
        value: 0,
        state: null,
      }),
    },
  },
  computed: {
    formattedValue() {
      return (this.rating.value).toFixed(2);
    },
  },
};
</script>
