<template>
  <div :class="{'field' : true, 'd-flex' : singleLine}">
    <div class="d-flex align-items-center mr-2">
      <feather-icon
        v-if="icon"
        :icon="icon"
        class="mr-75"
      />
      <span class="font-weight-bold">{{
        t(`${alias}.field.${field}.label`)
      }}</span>
    </div>
    <div>
      <EditInput
        :key="value"
        :model="value"
        :toggle-only-by-icon="false"
        :placeholder="t(`${alias}.field.${field}.placeholder`)"
        :readonly="readonly"
        :mask="mask"
        :guide="guide"
        @input="onInput"
      >
        {{ value || t(`${alias}.field.${field}.placeholder`) }}
      </EditInput>
    </div>
  </div>
</template>

<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { toRefs, ref } from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import EditInput from '@/components/editInput/EditInput.vue';
import { useStore } from '@/hooks/useStore';
// import { useStore } from '@/hooks/useStore';

export default {
  components: {
    EditInput,
  },
  props: {
    icon: String,
    value: [String, Number],
    alias: String,
    field: String,
    readonly: Boolean,
    money: Boolean,
    singleLine: {
      type: Boolean,
      required: false
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { money, field } = toRefs(props);
    const mask = ref(null);
    const guide = ref(false);
    const store = useStore();

    const { currency } = store?.state?.user?.account || 'RUB';
    const currencyTitle = store.state.user.currencies.find((i) => i.value === currency).title;
    const currencyCode = ref(currencyTitle.split(' ')[0]);

    if (money.value) {
      const numberMask = createNumberMask({
        prefix: '',
        suffix: currencyCode.value,
        thousandsSeparatorSymbol: ' ',
      });
      mask.value = numberMask;
      guide.value = false;
    }

    const onInput = (value) => {
      const numVal = `${value.replace(/[^0-9]/g, '')}`;
      emit('updateField', {
        field: field.value, value: money.value ? numVal : value,
      });
    };

    return {
      t,
      mask,
      guide,
      onInput,
      currencyCode,
    };
  },
};
</script>
