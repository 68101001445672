<template>
  <div v-if="request.status && request.status.id">
    <div class="font-weight-bold mb-25">
      <Select
        :id="request.status_id"
        :value="request.status"
        entity="request"
        alias="requests"
        field="status"
        field-id="status_id"
        get-options-action="requests/statuses"
        :return-object="!request.id"
        no-label
        style="margin-bottom: -8px"
        dropdown-class="statusSelect"
        :readonly="!$store.getters['user/permission']('requests:update')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
    </div>
    <BProgress
      :max="maxLevel"
      height="12px"
      :class="['request-status']"
    >
      <BProgressBar
        :value="statusValue"
        height="12px"
        :style="getStatusColor(statusId)"
      />
    </BProgress>
  </div>
</template>

<script>
import { BProgress, BProgressBar } from 'bootstrap-vue';
import { toRefs, computed, ref } from '@vue/composition-api';
import { useStore } from '@/hooks/useStore';
import Select from '../fields/select/index.vue';

export default {
  components: {
    BProgressBar,
    BProgress,
    Select,
  },
  props: {
    request: Object,
  },
  setup(props) {
    const store = useStore();
    const { request } = toRefs(props);
    const statusName = computed(() => request.value.status?.name);
    const statusId = computed(() => request.value.status?.id);

    const maxLevel = ref(100);
    const statuses = ref([]);
    const getStatuses = async () => {
      statuses.value = (await store.dispatch('requests/statuses'))?.data;
      maxLevel.value = (statuses.value?.length - 1);
    };
    getStatuses();
    const getStatusColor = (statusId) => {
      const status = statuses.value?.find((i) => +statusId === i.id);
      const prefix = 'background-color: ';
      if (status) {
        return `${prefix}rgb(${status.color});`;
      } return undefined;
    };

    // eslint-disable-next-line max-len
    const statusValue = computed(() => statuses.value.findIndex((i) => i.id === statusId.value) + 1);

    return {
      statusName,
      statusId,
      statuses,
      getStatusColor,
      maxLevel,
      statusValue,
    };
  },
};
</script>

<style lang="sass">
.dropdown-menu.statusSelect
  & a:hover
    color: #82868B
  & li:nth-child(1) a:hover
    background-color: rgba(45, 156, 219, 0.12)
  & li:nth-child(2) a:hover
    background-color: rgba(255, 159, 67, 0.12)
  & li:nth-child(3) a:hover
    background-color: rgba(115, 103, 240, 0.12)
  & li:nth-child(4) a:hover
    background-color: rgba(40, 199, 111, 0.12)
  & li:nth-child(5) a:hover
    background-color: rgba(234, 84, 85, 0.12)
  & li:nth-child(6) a:hover
    background-color: rgba(108, 117, 125, 0.12)
</style>

<style lang="sass" scoped>
.request-status
  &.status-1
    & .progress-bar
      background-color: #2D9CDB
  &.status-2
    & .progress-bar
      background-color: #FF9F43
  &.status-3
    & .progress-bar
      background-color: #7367F0
  &.status-4
    & .progress-bar
      background-color: #28C76F
  &.status-5
    & .progress-bar
      background-color: #EA5455
  &.status-6
    & .progress-bar
      background-color: #BABFC7
</style>
