<template>
  <BCard class="request-info">
    <b-modal
        :id="`request-delete-modal-${item.id}`"
        centered
        cancel-variant="outline-secondary"
        ok-variant="danger"
        title="Удаление заявки"
        size="sm"
        ok-title="Удалить"
        cancel-title="Отмена"
        @ok="$emit('deleteEntity')"
    >
      <h5>Вы действительно хотите удалить данную заявку?</h5>
    </b-modal>
    <div
      v-if="!isNew"
      class="position-absolute mr-1 mt-1 text-dark"
      style="top: 0; right: 0"
    >
      <b-dropdown
        v-if="$store.getters['user/permission']('requests:delete')"
        variant="link"
        toggle-class="text-decoration-none p-0 toggler-grey"
        no-caret
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="24"
          />
        </template>
        <b-dropdown-item
            v-b-modal="`request-delete-modal-${item.id}`"
            @click.prevent
        >
          <FeatherIcon
            icon="Trash2Icon"
            class="mr-1 text-danger"
          />
          <span class="text-danger">
            {{ t('settings.delete') }}
          </span>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="facility-info__header">
      <h4 class="m-0 mr-2">
        <EditInput
          :model="item.name"
          :toggle-only-by-icon="false"
          :placeholder="t('requests.field.name.placeholder')"
          :readonly="!$store.getters['user/permission']('requests:update')"
          @input="$emit('updateField', { field: 'name', value: $event })"
        >
          {{ item.name || t('requests.field.name.label') }}
        </EditInput>
      </h4>
      <div
        v-if="item.number"
        class="text-muted mb-25"
      >
        # {{ item.number }}
      </div>
      <Tags
        v-if="!isNew"
        :entity="item"
        entity-name="request"
        @input="$emit('needToUpdate')"
      />
      <ReqeustStatus
        :request="item"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
    </div>
    <div class="facility-info__body info-card mt-1">
      <Select
        :id="item.type_id"
        :value="item.type"
        entity="request"
        alias="requests"
        field="type"
        field-id="type_id"
        get-options-action="requests/types"
        icon="StarIcon"
        :return-object="!item.id"
        :readonly="!$store.getters['user/permission']('requests:update')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <Select
        :id="item.work_type_id"
        :value="item.work_type"
        entity="request"
        alias="requests"
        field="work_type"
        field-id="work_type_id"
        get-options-action="requests/workTypes"
        icon="FlagIcon"
        :return-object="!item.id"
        :readonly="!$store.getters['user/permission']('requests:update')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <EmployeeSelect
        :value="item.employees"
        :request="item"
        alias="requests"
        :return-object="!item.id"
        :readonly="!$store.getters['user/permission']('requests:update')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <Select
        :id="item.manager_id"
        :value="item.manager"
        entity="request"
        alias="requests"
        field="manager"
        field-id="manager_id"
        get-options-action="employees/getEmployeesForSelector"
        autocomplete
        icon="UserIcon"
        :return-object="!item.id"
        :readonly="!$store.getters['user/permission']('requests:update')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <Calendar
        :value="item.deadline_at"
        entity="request"
        alias="requests"
        field="deadline_at"
        icon="CalendarIcon"
        :start-weekday="1"
        time
        :right="isMobile"
        :past="false"
        :return-object="!item.id"
        :readonly="!$store.getters['user/permission']('requests:update')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <Base
        :value="item.price"
        entity="request"
        alias="requests"
        field="price"
        icon="DollarSignIcon"
        money
        :return-object="!item.id"
        :readonly="!$store.getters['user/permission']('requests:update')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
      <Base
        :value="item.description"
        entity="request"
        alias="requests"
        field="description"
        icon="InfoIcon"
        :return-object="!item.id"
        :readonly="!$store.getters['user/permission']('requests:update')"
        @updateField="$emit('updateField', $event)"
        @updateFields="$emit('updateFields', $event)"
      />
    </div>
  </BCard>
</template>

<script>
import { BCard, BDropdown, BDropdownItem } from 'bootstrap-vue';
import { computed, ref, toRefs } from '@vue/composition-api';
import Tags from '@/components/tags/Tags.vue';
import { useI18n } from '@/hooks/useI18n';
import EditInput from '@/components/editInput/EditInput.vue';
import Select from '@/components/fields/select/index.vue';
import Calendar from '@/components/fields/calendar/index.vue';
import Base from '@/components/fields/base/index.vue';
import EmployeeSelect from '@/components/fields/employeeSelect/index.vue';
import { useStore } from "@/hooks/useStore";
import ReqeustStatus from './ReqeustStatus.vue';

export default {
  components: {
    BCard,
    Tags,
    BDropdown,
    BDropdownItem,
    EditInput,
    Select,
    Calendar,
    Base,
    EmployeeSelect,
    ReqeustStatus,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    noClient: Boolean,
  },
  setup(props) {
    const { item } = toRefs(props);
    const { t } = useI18n();
    const isNew = computed(() => !item.value.id);
    const isMobile = ref(false);
    const store = useStore();

    isMobile.value = store.state.app.isMobile;

    return {
      t,
      isNew,
      isMobile,
    };
  },
};
</script>

<style lang="sass">
.request-info
  display: flex
  flex-direction: column
  flex: 1
  & .facility-info__header
    display: flex
    flex-direction: column
    flex: 1
    gap: 0
  .edit-input__toggle__value
    white-space: normal
</style>
