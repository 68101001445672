<template>
  <div class="d-flex field align-items-center">
    <div class="d-flex align-items-center mr-2">
      <feather-icon
        v-if="icon"
        :icon="icon"
        :size="iconSize"
        class="mr-75"
        @click="openDatePicker"
      />
      <span
        v-if="!onlyIcon && withLabel"
        class="font-weight-bold"
      >{{
        t(`${alias}.field.${field}.label`)
      }}</span>
    </div>
    <div class="datepicker-container">
      <EditInput
        v-if="!onlyIcon"
        :model="formattedDate"
        :toggle-only-by-icon="false"
        :readonly="readonly"
        @input="$emit('updateField', { field, value: $event })"
        @edit="openDatePicker"
      >
        {{ formattedDate || t(`${alias}.field.${field}.placeholder`) }}
      </EditInput>
      <BDropdown
        ref="dropdown"
        :style="`top: ${offset}px`"
        no-caret
        :right="right"
      >
        <template #button-content />

        <BCalendar
          v-bind="labels"
          hide-header
          label-help=""
          :start-weekday="1"
          :date-disabled-fn="dateDisabled"
          @selected="dateSelected"
        />
      </BDropdown>

      <BDropdown
        ref="timeDropdown"
        :style="`top: ${offset}px`"
        no-caret
        :right="right"
      >
        <template #button-content />

        <div class="d-flex flex-column gap-2 p-1">
          <b-time
            v-model="timeModel"
          />
          <BButton
            variant="primary"
            @click="timeSelected"
          >
            Сохранить
          </BButton>
        </div>
      </BDropdown>
    </div>
  </div>
</template>

<script>
import { computed, ref, toRefs } from '@vue/composition-api';
import {
  BDropdown, BCalendar, BTime, BButton,
} from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import EditInput from '@/components/editInput/EditInput.vue';
import moment from "moment/moment";

export default {
  components: {
    EditInput,
    BDropdown,
    BCalendar,
    BTime,
    BButton,
  },
  props: {
    field: String,
    value: String,
    alias: String,
    icon: String,
    iconSize: {
      type: String,
      default: '14',
    },
    readonly: Boolean,
    onlyIcon: Boolean,
    right: Boolean,
    offset: Number,
    time: Boolean,
    past: {
      type: Boolean,
      default: true,
    },
    withLabel: {
      type: Boolean,
      default: true,
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const {
      field, value, readonly, time,
    } = toRefs(props);
    const formattedDate = computed(() => (value.value
      ? new Date(value.value.split(' ').join('T')).toLocaleString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
      : null));

    const timeDropdown = ref(null);
    const timeModel = ref('00:00:00');
    if (value.value && value.value.split(' ').length === 2) {
      // eslint-disable-next-line prefer-destructuring
      timeModel.value = value.value.split(' ')[1];
    }
    const dateModel = ref(null);
    const openTimePicker = () => {
      timeDropdown.value.show(true);
    };
    const timeSelected = () => {
      const [hours, minutes] = timeModel.value.split(":").map(Number);
      const [year, month, day] = dateModel.value.split("-").map(Number);
      const currentDate = new Date();
      currentDate.setHours(hours);
      currentDate.setMinutes(minutes);
      currentDate.setSeconds(0);
      currentDate.setFullYear(year, month - 1, day);
      const utcTime = currentDate.toUTCString();
      dateModel.value = moment(utcTime).utc().format('YYYY-MM-DD');
      timeModel.value = moment(utcTime).utc().format('HH:mm');
      const fixedDatetime = `${dateModel.value}T${timeModel.value}:00.000000Z`;
      timeModel.value = '00:00:00';
      emit('updateField', { field: field.value, value: fixedDatetime });
      timeDropdown.value.hide(true);
    };

    const dropdown = ref(null);
    const openDatePicker = () => {
      if (readonly.value) return;
      dropdown.value.show(true);
    };
    const dateSelected = (e) => {
      const formattedEDate = e;
      dateModel.value = formattedEDate;
      const fixedDate = `${formattedEDate} 00:00:00`;
      if (!time.value) emit('updateField', { field: field.value, value: fixedDate });
      else openTimePicker();
      dropdown.value.hide(true);
    };

    const labels = {
      labelPrevDecade: 'Прошлое десятилетие',
      labelPrevYear: 'Прошлый год',
      labelPrevMonth: 'Прошлый месяц',
      labelCurrentMonth: 'Текущий месяц',
      labelNextMonth: 'Следующий месяц',
      labelNextYear: 'Следующий год',
      labelNextDecade: 'Следующее десятилетие',
      labelToday: 'Сегодня',
      labelSelected: 'Выбранная дата',
      labelNoDateSelected: 'Дата не выбрана',
      labelCalendar: 'Календарь',
      labelNav: 'Навигация',
      labelHelp: '',
    };

    const { past } = toRefs(props);
    const dateDisabled = (ymd, date) => {
      if (!past.value) {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 1);
        if (currentDate.getTime() > date.getTime()) return true;
      }

      return false;
    };

    return {
      t,
      formattedDate,
      openDatePicker,
      openTimePicker,
      dateSelected,
      timeSelected,
      dropdown,
      timeDropdown,
      labels,
      timeModel,
      dateDisabled,
    };
  },
};
</script>

<style lang="sass">
.datepicker-container
  position: relative
  & .dropdown
    position: absolute
    & > button
      z-index: -1
      pointer-events: none
      height: 0px
      width: 0px
      min-height: 0px
      min-width: 0px
      display: block
      flex: 0
      padding: 0
      margin: 0
      border: unset
    & > ul
      margin-top: 0
      padding-bottom: 0
      & .b-calendar-grid-help
        display: none
</style>
