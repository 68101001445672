<template>
  <div class="field field-select">
    <div class="d-flex align-items-center mr-2">
      <feather-icon
        icon="UserIcon"
        class="mr-75"
      />
      <span class="font-weight-bold">{{ t(`${alias}.field.employees.label`) }}</span>
    </div>
    <div
      v-if="!readonly"
      v-b-modal.employeeSelector
    >
      <div v-if="!value || !value[0]">
        {{ t(`${alias}.field.employees.placeholder`) }}
      </div>
      <div v-else>
        {{ textValue }}
      </div>
    </div>
    <div v-else>
      <div v-if="!value || !value[0]">
        {{ t(`${alias}.field.employees.placeholder`) }}
      </div>
      <div v-else>
        {{ textValue }}
      </div>
    </div>

    <b-modal
      id="employeeSelector"
      ref="employeeSelector"
      size="xl"
      hide-footer
      title="Выбор сотрудников"
      body-class="m-0 p-0"
      centered
      no-body
    >
      <employees-list
        get-action="employees/getEmployeesForSelector"
        one-page
        selectable
        center-on-request
        no-cache
        :selected="value"
        :request="request"
        no-links
        @select="onInput"
      />
      <div class="d-flex gap-2 m-2">
        <b-button
          variant="primary"
          @click="update"
        >
          Сохранить
        </b-button>
        <b-button @click="cancel">
          Отменить
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { computed, toRefs, ref } from '@vue/composition-api';
import { BModal, BButton } from 'bootstrap-vue';
import { useI18n } from '@/hooks/useI18n';
import EmployeesList from '@/views/employees/list/EmployeesList.vue';
import { useStore } from '@/hooks/useStore';

export default {
  components: {
    BModal,
    EmployeesList,
    BButton,
  },
  props: {
    alias: String,
    value: Array,
    returnObject: Boolean,
    readonly: Boolean,
    request: {
      type: Object,
      default: () => {}
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { value, returnObject } = toRefs(props);
    const { t } = useI18n();
    const textValue = computed(() => {
      if (value.value) {
        return value.value.map((i) => `${i.firstname} ${i.surname}`).join(', ');
      }
      return '';
    });

    const employeeSelector = ref(null);
    const selected = ref(value.value?.employees_ids || []);
    const onInput = (selectedLocal) => {
      selected.value = selectedLocal;
    };
    const closeModal = () => {
      employeeSelector.value.hide();
    };
    const update = async () => {
      closeModal();
      if (returnObject.value) {
        const employees = (await store.dispatch('employees/getEmployeesForSelector'))?.data || [];
        const model = {
          employees_ids: selected.value,
        };
        model.employees = employees.filter((i) => selected.value.includes(i.id));
        emit('updateFields', { model });
      } else {
        emit('updateField', { field: 'employees_ids', value: selected.value, update: true });
      }
    };
    const cancel = () => {
      closeModal();
    };

    return {
      t,
      textValue,
      employeeSelector,
      onInput,
      update,
      cancel,
    };
  },
};
</script>

<style lang="sass">
#employeeSelector
  .employees-list
    max-height: calc(85vh - 136px)
    overflow: auto
    .requestsMap
      height: calc(85vh - 194px)
      top: 0
</style>
