<template>
  <div class="employees-list">
    <b-card no-body>
      <!-- Main Table -->
      <BOverlay
        :show="isProcessing"
        opacity="0.8"
        variant="transparent"
      >
        <b-tabs
          v-model="tabsModel"
          no-nav-style
          class="employees-tabs"
          lazy
        >
          <b-tab>
            <template #title>
              <feather-icon icon="ListIcon" />
            </template>

            <b-table
              ref="usersTable"
              class="position-relative min-height"
              hover
              selectable
              select-mode="single"
              :items="employees"
              responsive
              :fields="customTableFields"
              :per-page="0"
              :no-local-sorting="true"
              :sort-by.sync="sortBy"
              :sort-desc.sync="isSortDirDesc"
              show-empty
              :empty-text="t('employees.table.empty-text')"
              @row-clicked="onRowClicked"
            >
              <!-- Column: Employee -->
              <template #cell(firstname)="{ item: employee }">
                <div class="d-flex align-items-center gap-2">
                  <div v-if="selectable">
                    <b-form-checkbox
                      :id="`selected-${employee.id}`"
                      v-model="selectedLocal"
                      :value="employee.id"
                      name="selected"
                    />
                  </div>
                  <employee-small-card :employee="employee" />
                </div>
              </template>

              <!-- Column: Workload -->
              <template #cell(workload)="{ item: employee }">
                <employee-workload :workload="employee.workload" />
              </template>

              <!-- Column: Rating -->
              <template #cell(rating)="{ item: employee }">
                <EmployeeRating :rating="employee.rating" />
              </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  v-if="totalRows > 10"
                  cols="12"
                  sm="6"
                  class="
                    d-flex
                    align-items-center
                    justify-content-center
                    justify-content-sm-start
                    text-muted
                  "
                >
                  <span class="mr-1">{{ t('app.table.show-by') }}</span>
                  <b-form-group style="width: 90px; margin: 0">
                    <AppSelect
                      v-model="pagination.perPage"
                      style="width: 100%"
                      :searchable="false"
                      label="title"
                      :clearable="false"
                      :options="perPageOptions"
                    />
                  </b-form-group>
                  <span class="ml-1">
                    {{ t('app.table.of', { total: totalRows }) }}
                  </span>
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-if="totalPages > 1"
                    v-model="pagination.page"
                    :total-rows="totalRows"
                    :per-page="pagination.perPage"
                    first-number
                    last-number
                    :limit="isMobile ? 1 : 5"
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab :class="{'pr-2' : !isMobile }">
            <template #title>
              <feather-icon icon="MapPinIcon" />
            </template>

            <employees-requests-map
              :employees="employees"
              :selectable="selectable"
              :current-request="request"
              :center-on-request="centerOnRequest"
              :last-update-state="'tabs'"
            />

            <ScrollWatcher @scrolled="scrollToEndMapView" />
          </b-tab>

          <template #tabs-end>
            <div class="ml-2">
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                  class="d-flex align-items-center justify-content-start"
                >
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <feather-icon icon="SearchIcon" />
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="searchQuery"
                      debounce="500"
                      :placeholder="t('dictionary.search')"
                    />
                  </b-input-group>
                </b-col>
                  <b-col
                          class="d-flex justify-content-end"
                          cols="12"
                          :md="tabsModel == 1 && !isMobile ? '5' : '8'"
                  >
                      <sorting-configure
v-show="tabsModel !== 1"
class="mr-0"
                                         :list="tableColumns"
                                         @changeSorting="(e) => tableColumns = e"
                      />
                  </b-col>
              </b-row>
            </div>
          </template>
        </b-tabs>
      </BOverlay>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BOverlay,
  BPagination,
  BRow,
  BTable,
  BTabs,
  BTab,
  BFormCheckbox,
} from 'bootstrap-vue';
import {
  computed, ref, watch, toRefs, provide,
} from '@vue/composition-api';
import {useLocalStorage} from '@vueuse/core';
import AppSelect from '@/components/appSelect/AppSelect.vue';
import { useI18n } from '@/hooks/useI18n';
import { useStore } from '@/hooks/useStore';
import { useRouter } from '@/hooks/useRouter';
import EmployeeSmallCard from '@/components/employeeSmallCard/EmployeeSmallCard.vue';
import EmployeeWorkload from '@/components/employeeWorkload/EmployeeWorkload.vue';
import EmployeesRequestsMap from '@/components/employeesRequestsMap/EmployeesRequestsMap.vue';
import EmployeeRating from '@/components/employeeRating/EmployeeRating.vue';
import ScrollWatcher from '@/components/scrollWatcher/ScrollWatcher.vue';
import useCache from '@/hooks/useCache';
import SortingConfigure from '@/components/sortingConfigure/sortingConfigure.vue';

export default {
  name: 'EmployeesList',
  components: {
    SortingConfigure,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BInputGroupPrepend,
    BInputGroup,
    BFormGroup,
    BOverlay,
    AppSelect,
    EmployeeSmallCard,
    EmployeeWorkload,
    BTabs,
    BTab,
    EmployeesRequestsMap,
    EmployeeRating,
    ScrollWatcher,
    BFormCheckbox,
  },
  props: {
    selectable: Boolean,
    selected: Array,
    noLinks: Boolean,
    noCache: Boolean,
    getAction: {
      type: String,
      default: 'employees/getEmployees',
    },
    request: {
      type: Object,
      default: () => {}
    },
    centerOnRequest: {
      type: Boolean,
      default: false
    },
    onePage: Boolean,
  },
  setup(props, { emit }) {
    const {
      selected, noLinks, noCache, getAction, onePage,
    } = toRefs(props);
    const { t } = useI18n();
    const store = useStore();
    const { router } = useRouter();
    const isMobile = ref(false);

    const lastUpdateState = ref('');
    const cacheParams = ref({
      prefix: 'employeesTable',
      storage: !noCache.value,
      url: !noCache.value,
    });
    const cache = useCache();
    const tabsModel = cache.create({
      defaultValue: 0,
      name: 'tabsModel',
      ...cacheParams.value,
      number: true,
    });

    isMobile.value = store.state.app.isMobile;

    const usersTable = ref(null);
    const tableColumnsArray = [
      {key: 'firstname', label: t('employees.field.employee'), sortable: true, visible: true},
      // { key: 'workload', label: t('employees.field.workload'), sortable: true },
      // { key: 'rating', label: t('employees.field.rating'), sortable: true },
      {key: 'phone', label: t('employees.field.phone.list'), sortable: false, visible: true},
      {key: 'email', label: t('employees.field.email'), sortable: false, visible: true},
    ];
    const tableColumns = useLocalStorage('ClientListColumns', tableColumnsArray);
    const customTableFields = computed(() => tableColumns.value.filter((i) => i.visible));

    const employees = ref([]);
    const totalRows = ref(0);

    const paginationPage = cache.create({
      defaultValue: 1,
      name: 'page',
      ...cacheParams.value,
      storage: false,
    });
    const paginationPerPage = cache.create({
      defaultValue: 25,
      name: 'perPage',
      ...cacheParams.value,
    });

    const pagination = ref({
      page: paginationPage,
      perPage: paginationPerPage,
    });
    const totalPages = computed(() => Math.ceil(totalRows.value / pagination.value.perPage));
    const perPageOptions = [10, 25, 50, 100];

    const searchQuery = cache.create({
      defaultValue: '',
      name: 'query',
      ...cacheParams.value,
      // storage: false,
    });
    const sortBy = cache.create({
      defaultValue: 'id',
      name: 'sortBy',
      ...cacheParams.value,
    });
    const isSortDirDesc = cache.create({
      defaultValue: false,
      name: 'isSortDirDesc',
      ...cacheParams.value,
      boolean: true,
    });

    const isProcessing = ref(false);
    const getClients = (payload) => store.dispatch(getAction.value, payload);

    const getList = async () => {
      if (isProcessing.value) return;
      isProcessing.value = true;
      const { result, data } = await getClients({
        q: searchQuery.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        ...pagination.value,
      });
      isProcessing.value = false;

      if (result) {
        if (onePage.value) {
          employees.value = data;
          return;
        }

        if (tabsModel.value === 1) employees.value.push(...data.list);
        else employees.value = data.list;

        totalRows.value = data.pagination.total;
      }
    };

    const page = computed(() => pagination.value.page);
    const perPage = computed(() => pagination.value.perPage);
    watch(
      page,
      (value, lastValue) => {
        if (value === lastValue) return;
        lastUpdateState.value = 'pagination';
        getList();
      },
      { deep: true },
    );

    const isNeedToUpdateSortNavigation = ref(false);
    let sortNavigationDebounceTimer = null;
    watch([sortBy, isSortDirDesc], () => {
      clearTimeout(sortNavigationDebounceTimer);
      if (sortBy.value) {
        sortNavigationDebounceTimer = setTimeout(() => {
          isNeedToUpdateSortNavigation.value = true;
          sortNavigationDebounceTimer = null;
        }, 5);
      }
    });
    watch(isNeedToUpdateSortNavigation, (value) => {
      if (value) {
        getList();
        isNeedToUpdateSortNavigation.value = false;
      }
    });

    watch(perPage, () => {
      pagination.value.page = 1;
      lastUpdateState.value = 'pagination';
      getList();
    });

    watch(tabsModel, () => {
      lastUpdateState.value = 'tabs';
      if (+pagination.value.page !== 1) {
        employees.value = [];
        pagination.value.page = 1;
      }
    });

    watch(searchQuery, () => {
      lastUpdateState.value = 'search';
      employees.value = [];
      if (pagination.value.page !== 1) {
        pagination.value.page = 1;
      }
      getList();
    });

    const scrollToEndMapView = () => {
      const empLenght = employees.value.length;
      if (!isProcessing.value && pagination.value.page < totalPages.value && empLenght > 0) {
        pagination.value.page += 1;
      }
    };

    // Same as created();
    (async () => {
      getList();
    })();

    const onRowClicked = (employee) => {
      if (noLinks.value) return;
      router.push({ name: 'employee-view', params: { employeeId: employee.id } });
    };

    const selectedLocal = ref([]);
    watch(
      selected,
      (selectedValue) => {
        selectedLocal.value = selectedValue ? selectedValue.map((i) => i.id) : [];
      },
      { immediate: true },
    );
    watch(selectedLocal, (selectedLocalValue) => {
      emit('select', selectedLocalValue);
    });
    provide('selectedLocal', selectedLocal);

    return {
      t,
      isMobile,
      customTableFields,
      tabsModel,
      lastUpdateState,

      usersTable,
      tableColumns,

      employees,
      totalRows,

      pagination,
      totalPages,
      perPageOptions,
      scrollToEndMapView,

      searchQuery,
      sortBy,
      isSortDirDesc,

      isProcessing,

      onRowClicked,

      selectedLocal,
    };
  },
};
</script>

<style lang="sass">
@import 'src/@core/scss/base/core/colors/palette-variables.scss'

.employees-list
  display: flex
  flex-direction: column
  > .card
    flex: 1
.employees-tabs
  .nav
    margin: 1.5rem !important
    display: flex
    align-items: center
    & > .nav-item
      width: 39px
      height: 34px
      border: 1px solid var(--primary)
      & + .nav-item
        margin-left: -1px
      &:first-child
        border-top-left-radius: 0.25rem
        border-bottom-left-radius: 0.25rem
      &:nth-last-child(2)
        border-top-right-radius: 0.25rem
        border-bottom-right-radius: 0.25rem
      & + div
        flex: 1
        & .form-control
          height: 38px
      & > .nav-link
        width: 100%
        height: 100%
        display: flex
        align-items: center
        justify-content: center
        &.active
          background-color: transparentize($primary, 0.80)
    & > .nav-item:first-child
      // border-right: 1px solid var(--primary)
</style>
